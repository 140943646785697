import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';
import { InfoCircle } from 'styled-icons/boxicons-regular/InfoCircle';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PizzaSizes from '../components/pizzaSizes';
import Nav from '../components/nav';

import { Main, Menu, Item } from '../styles';

const StyledAppBar = styled(AppBar)`
  background-color: #ad1d41;
  color: #fafafa;
`;

const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: #00d664;
  }
  .MuiTabs-scrollButtons {
    color: #00d664;
  }
  /* @media (min-width: 768px) {
    .MuiTabs-flexContainer {
      justify-content: center;
      Only use if the number of tab don't need to scroll 
    }
  } */
`;

const Obs = () => (
  <div className="menu-obs">
    <p>
      <InfoCircle size={23} /> Todas as nossas pizzas contém molho de tomate,
      azeitona e orégano
    </p>
  </div>
);

const Obs2 = () => (
  <div className="menu-obs2">
    <p>
      <InfoCircle size={26} /> Ingredientes adicionais? Consulte-nos.
    </p>
  </div>
);

const IndexPage = () => {
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

  return (
    <Layout>
      <SEO
        title="Cardápio"
        keywords={[
          `pizzas`,
          `pizzas doces`,
          `pizza`,
          `pizzas gourmet`,
          `pizzas especiais`,
          `borda paozinho`,
          `borda vulcao`,
          `sucos`,
          `bebidas`,
          `disk entregas`,
        ]}
      />
      <Main>
        <Nav />

        <h1>
          PIZZA<span>!</span>
          <span>!</span>
          <span>!</span>
        </h1>
        <h2>No tamanho da sua fome</h2>

        <PizzaSizes />

        <StyledAppBar position="sticky" color="default" square elevation={0}>
          <StyledTabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="scrollable"
            scrollButtons="on"
          >
            <Tab label="Promocionais" />
            <Tab label="Tradicionais" />
            <Tab label="Especiais" />
            <Tab label="Gourmet" />
            <Tab label="Doces" />
            <Tab label="Bordas" />
          </StyledTabs>
        </StyledAppBar>

        <SwipeableViews
          index={value}
          onChangeIndex={handleChangeIndex}
          animateHeight
        >
          <Menu id="menu">
            <h1 className="menu-title">Pizzas Promocionais</h1>

            <Obs />

            <div className="item-sizes">
              <div>
                PEQ
                <div className="custom-shape-divider-bottom-1605973662">
                  <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M1200 0L0 0 598.97 114.72 1200 0z"
                      className="shape-fill"
                    ></path>
                  </svg>
                </div>
              </div>

              <div>
                GRA
                <div className="custom-shape-divider-bottom-1605973662">
                  <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M1200 0L0 0 598.97 114.72 1200 0z"
                      className="shape-fill"
                    ></path>
                  </svg>
                </div>
              </div>

              <div>
                FAM
                <div className="custom-shape-divider-bottom-1605973662">
                  <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M1200 0L0 0 598.97 114.72 1200 0z"
                      className="shape-fill"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Alho</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 44
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 55
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 65
                  </h4>
                </div>
              </div>

              <p className="item-desc">Mussarela, parmesão e alho frito.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Baiana</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 44
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 55
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 65
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Mussarela, calabresa ralada e pimenta calabresa.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Calabresa I</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 44
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 55
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 65
                  </h4>
                </div>
              </div>

              <p className="item-desc">Calabresa e cebola.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Calabresa II</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 44
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 55
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 65
                  </h4>
                </div>
              </div>

              <p className="item-desc">Calabresa, mussarela e cebola.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Calabresa III</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 44
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 55
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 65
                  </h4>
                </div>
              </div>

              <p className="item-desc">Calabresa, mussarela e parmesão.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Grega</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 44
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 55
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 65
                  </h4>
                </div>
              </div>

              <p className="item-desc">Presuntos, ovos e mussarela.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Milho</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 44
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 55
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 65
                  </h4>
                </div>
              </div>

              <p className="item-desc">Milho, mussarela e catupiry.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Presunto</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 44
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 55
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 65
                  </h4>
                </div>
              </div>

              <p className="item-desc">Mussarela, presunto, tomate e cebola.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Presunto Especial</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 44
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 55
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 65
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Mussarela, presunto, tomate e catupiry.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Toscana</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 44
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 55
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 65
                  </h4>
                </div>
              </div>

              <p className="item-desc">Mussarela e calabresa ralada.</p>
            </Item>

            <Obs2 />
          </Menu>

          <Menu>
            <h1 className="menu-title">Pizzas Tradicionais</h1>

            <Obs />

            <div className="item-sizes">
              <div>
                PEQ
                <div className="custom-shape-divider-bottom-1605973662">
                  <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M1200 0L0 0 598.97 114.72 1200 0z"
                      className="shape-fill"
                    ></path>
                  </svg>
                </div>
              </div>

              <div>
                GRA
                <div className="custom-shape-divider-bottom-1605973662">
                  <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M1200 0L0 0 598.97 114.72 1200 0z"
                      className="shape-fill"
                    ></path>
                  </svg>
                </div>
              </div>

              <div>
                FAM
                <div className="custom-shape-divider-bottom-1605973662">
                  <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M1200 0L0 0 598.97 114.72 1200 0z"
                      className="shape-fill"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Atum I</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 51
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 66
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 79
                  </h4>
                </div>
              </div>

              <p className="item-desc">Mussarela, atum e cebola.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Atum II</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 67
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 80
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Mussarela, atum, cebola tomate e parmesão.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Bacon</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 50
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 63
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 76
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Mussarela, bacon fatiado ou triturado.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Calacatu</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 50
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 63
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 73
                  </h4>
                </div>
              </div>

              <p className="item-desc">Calabresa, mussarela e catupiry.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Frango I</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 49
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 61
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 73
                  </h4>
                </div>
              </div>

              <p className="item-desc">Frango, mussarela e catupiry.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Frango II</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 49
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 61
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 73
                  </h4>
                </div>
              </div>

              <p className="item-desc">Frango, milho e mussarela.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Frango III</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 66
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 80
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Frango, milho, bacon, palmito, mussarela e catupiry.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Lombinho I</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 51
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 63
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 77
                  </h4>
                </div>
              </div>

              <p className="item-desc">Lombo, mussarela e cebola.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Lombinho II</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 53
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 66
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 80
                  </h4>
                </div>
              </div>

              <p className="item-desc">Lombo, mussarela e catupiry.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">
                  Milho Especial <span>Novidade</span>
                </h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 50
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 65
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 80
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Milho, mussarela, requeijão e bacon em cubos.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Palmito I</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 49
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 54
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 74
                  </h4>
                </div>
              </div>

              <p className="item-desc">Palmito e mussarela.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Palmito II</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 51
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 66
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 77
                  </h4>
                </div>
              </div>

              <p className="item-desc">Palmito, mussarela e catupiry.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Palmito III</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 68
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 80
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Presunto, palmito, mussarela, tomate, cebola, catupiry e
                parmesão.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Portuguesa</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 65
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 80
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Presunto, calabresa fatiada, cebola, ervilha e mussarela.
              </p>
            </Item>

            <h1 className="menu-title">Pizzas de Queijo</h1>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Marguerita</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 47
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 60
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 70
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Mussarela, tomate, parmesão e manjericão.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Mussarela</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 47
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 60
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 70
                  </h4>
                </div>
              </div>

              <p className="item-desc">Mussarela e tomate.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Napolitana</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 47
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 60
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 70
                  </h4>
                </div>
              </div>

              <p className="item-desc">Mussarela, tomate e parmesão.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Dois Queijos</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 50
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 64
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 76
                  </h4>
                </div>
              </div>

              <p className="item-desc">Mussarela, provolone e tomate.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Quatro Queijos</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 53
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 67
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 81
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Mussarela, provolone, parmesão e catupiry.
              </p>
            </Item>

            <Obs2 />
          </Menu>

          <Menu>
            <h1 className="menu-title">Pizzas Especiais</h1>

            <Obs />

            <div className="item-sizes">
              <div>
                PEQ
                <div className="custom-shape-divider-bottom-1605973662">
                  <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M1200 0L0 0 598.97 114.72 1200 0z"
                      className="shape-fill"
                    ></path>
                  </svg>
                </div>
              </div>

              <div>
                GRA
                <div className="custom-shape-divider-bottom-1605973662">
                  <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M1200 0L0 0 598.97 114.72 1200 0z"
                      className="shape-fill"
                    ></path>
                  </svg>
                </div>
              </div>

              <div>
                FAM
                <div className="custom-shape-divider-bottom-1605973662">
                  <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M1200 0L0 0 598.97 114.72 1200 0z"
                      className="shape-fill"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Americana</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 67
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 80
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Presuntos, ovos, ervilhas, palmito, milho, bacon e mussarela.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Brócolis</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 67
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 80
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Brócolis, bacon, alho frito, mussarela, catupiry e parmesão.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Casteliana</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 67
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 80
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Presuntos, ovos, ervilhas, bacon, cebola, mussarela e catupiry.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Castelli</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 67
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 80
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Palmito, champgnon, creme de leite, mussarela e cheddar.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Doritos</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 67
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 80
                  </h4>
                </div>
              </div>

              <p className="item-desc">Mussarela, bacon, cheddar e doritos.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Escarola</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 67
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 80
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Escarola, bacon, mussarela e catupiry.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Mexicana</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 67
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 80
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Lombo, bacon, champgnon, mussarela, peperoni e pimentão.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Mineira</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 67
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 80
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Mussarela, linguiça caipira, bacon e catupiry.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Moda da Casa</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 67
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 80
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Lombo, brócolis, palmito, mussarela, tomate, cebola e parmesão.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Peperoni</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 67
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 80
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Lombo, bacon, mussarela, peperoni e parmesão.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Strogonoff</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 67
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 80
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Strogonoff de frango, champgnon, mussarela, catupry e batata
                palha.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Tomato</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 67
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 80
                  </h4>
                </div>
              </div>

              <p className="item-desc">Mussarela, tomate seco e rúcula.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Tropeira Especial</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 67
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 80
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Carne seca, bacon, cebola, mussarela e catupiry.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Vegetariana</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 67
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 80
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Brócolis, palmito, mussarela, tomate e rúcula.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Pizzaiolo</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 67
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 80
                  </h4>
                </div>
              </div>

              <p className="item-desc">Mussarela, calabresa, ovos e cebola.</p>
            </Item>

            <Obs2 />
          </Menu>

          <Menu>
            <h1 className="menu-title">Pizzas Gourmet</h1>

            <Obs />

            <div className="item-sizes">
              <div>
                PEQ
                <div className="custom-shape-divider-bottom-1605973662">
                  <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M1200 0L0 0 598.97 114.72 1200 0z"
                      className="shape-fill"
                    ></path>
                  </svg>
                </div>
              </div>

              <div>
                GRA
                <div className="custom-shape-divider-bottom-1605973662">
                  <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M1200 0L0 0 598.97 114.72 1200 0z"
                      className="shape-fill"
                    ></path>
                  </svg>
                </div>
              </div>

              <div>
                FAM
                <div className="custom-shape-divider-bottom-1605973662">
                  <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M1200 0L0 0 598.97 114.72 1200 0z"
                      className="shape-fill"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Peito de Peru</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 58
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 72
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 89
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Mussarela, champgnon, peito de peru, tomate, parmesão e cream
                cheese.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Peperoni c/ Cream Cheese</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 58
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 72
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 89
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Lombo, mussarela, peperoni e cream cheese.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Hambúrguer</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 58
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 72
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 89
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Presunto, hambúrguer, milho, bacon, tomate, mussarela, catupiry,
                catchup e mostarda.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Portuguesa Especial</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 58
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 72
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 89
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Presunto, calabresa fatiada, ovos, milho, bacon, cebola e
                mussarela.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Frango Filadélfia</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 58
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 72
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 89
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Frango desfiado, mussarela, bacon e cream cheese.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Lombo Filadélfia</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 58
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 72
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 89
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Lombo, mussarela, tomate, manjericão e cream cheese.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">
                  Costela <span>Novidade</span>
                </h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 58
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 72
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 89
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Mussarela, costela de boi, tomate picado, bacon, cebola e
                catupiry.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">
                  Supreme <span>Novidade</span>
                </h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 58
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 72
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 89
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Mussarela, peperoni, cebola, bacon, requeijão e alho frito.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Calzone</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 58
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 72
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 89
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Pizza fechada salpicada com parmesão (À sua escolha ou 5
                ingredientes).
              </p>
            </Item>

            <Obs2 />
          </Menu>

          <Menu>
            <h1 className="menu-title">Pizzas Doces</h1>

            <div className="item-sizes">
              <div>
                PEQ
                <div className="custom-shape-divider-bottom-1605973662">
                  <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M1200 0L0 0 598.97 114.72 1200 0z"
                      className="shape-fill"
                    ></path>
                  </svg>
                </div>
              </div>

              <div>
                GRA
                <div className="custom-shape-divider-bottom-1605973662">
                  <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M1200 0L0 0 598.97 114.72 1200 0z"
                      className="shape-fill"
                    ></path>
                  </svg>
                </div>
              </div>

              <div>
                FAM
                <div className="custom-shape-divider-bottom-1605973662">
                  <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M1200 0L0 0 598.97 114.72 1200 0z"
                      className="shape-fill"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Chocolate com Banana</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 67
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 77
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Chocolate ao leite coberto com bananas. (Borda de chocolate ao
                leite).
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Banana Nevada</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 67
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 77
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Mussarela, banana, chocolate branco, canela com açúcar. (Borda
                de chocolate ao leite).
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Chocolate</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 67
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 77
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Chocolate ao leite, granulados ou confeitos. (Borda de chocolate
                ao leite).
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Mesclada</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 67
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 77
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Chocolate ao leite e chocolate branco. (Borda de chocolate ao
                leite).
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Romeu &amp; Julieta</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 67
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 77
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Mussarela, catupiry e goiabada (Borda de Requeijão).
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Prestígio</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 52
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 67
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 77
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Chocolate ao leite e cocô ralado. (Borda de chocolate ao leite).
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Nutella</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 56
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 70
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 87
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Nutella coberto com morangos. (Borda de chocolate ao leite).
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">
                  Ferrero <span>Novidade</span>
                </h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 56
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 70
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 87
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Chocolate com avelã, castanha triturada e chocolate ao leite.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">
                  Maracujá Filadélfia <span>Novidade</span>
                </h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 56
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 70
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 87
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Cream cheese, chocolate branco e polpa de maracujá.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">
                  Ouro Branco <span>Novidade</span>
                </h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 56
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 70
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 87
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Mussarela, chocolate ao leite, bombom Ouro Branco e creme de
                leite.
              </p>
            </Item>
          </Menu>

          <Menu>
            <h1 className="menu-title">Bordas Recheadas</h1>

            <div className="item-sizes">
              <div>
                PEQ
                <div className="custom-shape-divider-bottom-1605973662">
                  <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M1200 0L0 0 598.97 114.72 1200 0z"
                      className="shape-fill"
                    ></path>
                  </svg>
                </div>
              </div>

              <div>
                GRA
                <div className="custom-shape-divider-bottom-1605973662">
                  <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M1200 0L0 0 598.97 114.72 1200 0z"
                      className="shape-fill"
                    ></path>
                  </svg>
                </div>
              </div>

              <div>
                FAM
                <div className="custom-shape-divider-bottom-1605973662">
                  <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M1200 0L0 0 598.97 114.72 1200 0z"
                      className="shape-fill"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">
                  Borda de Requeijão <span>Grátis</span>
                </h4>
              </div>

              <p className="item-desc"></p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Borda Tradicional</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 10
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 14
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 18
                  </h4>
                </div>
              </div>

              <ul className="item-desc">
                <li>Requeijão Especial - Cheddar - Cream Cheese - Chocolate</li>
              </ul>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Borda Vulcão</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 25
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 30
                  </h4>
                </div>
              </div>

              <ul className="item-desc">
                <li>
                  <strong>Escolha um recheio e uma cobertura:</strong>
                </li>
                <li>Recheio: Frango, Presunto ou Calabresa</li>
                <li>Cobertura: Catupiry, Cheddar ou Cream Cheese</li>
              </ul>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Borda Paõzinho</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 25
                  </h4>
                  <h4 className="item-price">
                    <span>$</span> 30
                  </h4>
                </div>
              </div>

              <ul className="item-desc">
                <li>
                  <strong>Escolha um recheio e uma cobertura:</strong>
                </li>
                <li>Recheio: Frango, Presunto ou Calabresa</li>
                <li>Cobertura: Catupiry, Cheddar ou Cream Cheese</li>
              </ul>
            </Item>
          </Menu>
        </SwipeableViews>
      </Main>
    </Layout>
  );
};

export default IndexPage;
